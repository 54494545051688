import './styles/globals.scss';

import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import Homepage from './pages';
import MyEmbed from './pages/examples/discord-embed-visualizer';
import MovieList from './pages/movielist';
import TimerComponent from './pages/timer';
import TimerPopout from './pages/timer/popout';

function Routr() {
    const routes = useRoutes([
        {
            path: '/',
            element: <Homepage />
        }, {
            path: '/examples/discord-embed-visualizer',
            element: <MyEmbed />
        }, {
            path: '/list/:guildId',
            element: <MovieList />
        }, {
            path: '/timer',
            element: <TimerComponent />
        }, {
            path: '/timer/popout',
            element: <TimerPopout />
        },
    ]);

    return routes;
};

const theme = createMuiTheme({
    palette: {
        mode: 'dark',
        primary: {
            // Purple and green play nicely together.
            main: blue[500],
        },
        secondary: {
            // This is green.A700 as hex.
            main: green[500],
        }
    },
});

const App = () => {
    return (<ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <div className="App">
                <Routr />
            </div>
        </Router>
    </ThemeProvider>);
}

export default App;
