import './index.scss';

import { Box, Button, ButtonGroup, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
        },
        timerContainer: {
            position: 'relative',
            width: 200,
            height: 200,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
        },
        progress: {
            position: 'absolute',
        },
        timer: {
            fontSize: 30,
        },
        resize: {
            animation: 'bouncey 1s ease-in-out infinite',
        },
        member: {
            fontWeight: 700,
        },
    }),
);

const TimerComponent = () => {
    const [startTime, setStartTime] = useState<number>(
        JSON.parse( localStorage.getItem('settings') )?.time || 120000
    );
    const [currentMember, setCurrentMember] = useState<string>('');

    const [changing, setChanging] = useState<boolean>(false);
    const [resizeable, setResizeable] = useState<boolean>(false);

    const classes = useStyles();

    useEffect( () => {
        let loaded = true;

        if ( loaded ) {
            window.onmessage = ({ data }: MessageEvent) => {
                console.log(data);
                if ( !!data.member ) {
                    setChanging(true);
                    setCurrentMember(data.member);
                    setTimeout(() => setChanging(false), 100);
                }

                if ( !!data.time ) {
                    setChanging(true);
                    setStartTime(data.time);
                    setTimeout(() => setChanging(false), 100);
                }
            }
        }

        return () => {
            loaded = false;
        }
    }, []);

    return <Box className={ classes.root }>
        <Helmet>
            <title>Timer</title>
        </Helmet>
        <Typography variant="h3" gutterBottom={ true } color="primary" className={ classes.member }>{ currentMember.toUpperCase() }<br /></Typography>
        <Timer
            initialTime={ startTime }
            direction="backward"
            timeToUpdate={ 100 }
            startImmediately={ false }
            checkpoints={[
                {
                    time: 0,
                    callback: () => setResizeable(true)
                },
            ]}
            onPause={ () => setResizeable(false) }
            onStop={ () => setResizeable(false) }
            onReset={ () => setResizeable(false) }
        >
            { ({ start, stop, pause, resume, reset, getTime, getTimerState, setTime }: any) => <>
                { changing && setTime(startTime) }
                <Box className={ clsx(classes.timerContainer, {
                    [classes.resize]: resizeable,
                }) }>
                    <CircularProgress
                        className={ classes.progress }
                        variant="determinate"
                        value={ (getTime() / startTime) * 100 }
                        size={ 200 }
                        thickness={ 4 }
                    />
                    <Box className={ classes.timer }>
                        { getTime() >= 0
                            ? <>
                                <Timer.Minutes /> m
                                {' '}
                                <Timer.Seconds /> s
                            </>
                            : <>
                                Times Up
                            </>
                        }
                    </Box>
                </Box>
                <br />
                <ButtonGroup>
                    <Button
                        onClick={ () => {
                            reset();
                            start();
                        } }
                    >
                        (Re)start
                    </Button>
                    <Button
                        onClick={ () => {
                            if ( !["PAUSED", "STOPPED"].includes(getTimerState()) ) {
                                pause();
                            } else {
                                stop();
                                reset();
                            }
                        } }
                    >
                        { !["PAUSED", "STOPPED"].includes(getTimerState()) ? "Stop" : "Reset" }
                    </Button>
                    <Button
                        onClick={ resume }
                    >
                        Resume
                    </Button>
                </ButtonGroup>
                <Button onClick={ () => window.open('/timer/popout', 'members', `width=400,height=600,top=${window.screen.height/2 - 600/2},left=${window.screen.width/2 - 400/2}`) }>
                    Settings
                </Button>
            </> }
        </Timer>
    </Box>;
};

export default TimerComponent;
