import './index.scss';

import { Button, ButtonGroup, Card, CardContent, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { DeleteForeverRounded } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            height: '100%',
            background: 'transparent',
        },
        cardContent: {
            height: '100%',
            overflow: 'auto !important',
        },
    }),
);

interface ISettings {
    time?: number;
    members?: string[];
}

const TimerPopout = () => {
    const [newMember, setNewMember] = useState<string>('');
    const [settings, setSettings] = useState<ISettings>(
        JSON.parse(localStorage.getItem('settings')) || { time: 120000, members: [] }
    );

    const classes = useStyles();

    const addMember = (name: string) => {
        setNewMember('');

        setSettings( (current: ISettings) =>  ({
            ...current,
            members: [
                ...current.members,
                name,
            ]
        }));
    };

    const remMember = (name: string) =>
        setSettings( (current: ISettings) => ({
            ...current,
            members: [
                ...current.members?.filter( (entry: string) => entry !== name ),
            ]
        }));

    const sendMember = (member: string) =>
        window.opener.postMessage({ member });

    const sendTime = (time: number) =>
        window.opener.postMessage({ time });

    useEffect( () => localStorage.setItem('settings', JSON.stringify(settings)), [ settings ]);

    return <Card variant="outlined" className={ classes.card }>
        <Helmet>
            <title>Timer Settings</title>
        </Helmet>
        <CardContent className={ classes.cardContent }>
            <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 12 }>
                    <TextField
                        value={ settings?.time / 1000 || 120 }
                        size="small"
                        type="number"
                        fullWidth
                        label="Countdown Time"
                        helperText="Time in seconds"
                        onChange={ ({ target: { value } }: any) => {
                            setSettings((current: ISettings) => ({
                                ...current,
                                time: value * 1000
                            }));
                            sendTime(value * 1000);
                        } }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <TextField
                        value={ newMember }
                        size="small"
                        fullWidth
                        label="Add Member"
                        onChange={ ({ target: { value }}) => setNewMember(value) }
                        onKeyDown={ ({ target: { value }, keyCode }: any) => keyCode === 13 && addMember(value) }
                    />
                </Grid>
                { settings.members?.map( (member: string, index: number) =>
                    <Grid item xs={ 12 } key={member + index}>
                        <Grid container>
                            <Grid item xs={ 12 }>
                                <ButtonGroup style={{ display: 'flex' }}>
                                    <Button
                                        style={{ flex: 1 }}
                                        variant="contained"
                                        onClick={ () => sendMember(member) }
                                    >
                                        { member }
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={ () => remMember(member) }
                                    >
                                        <DeleteForeverRounded />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                ) }
                <Grid item xs={ 12 }>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={ () => sendMember(' ') }
                    >
                        Clear Current Member
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

export default TimerPopout;
