import './index.scss';

import {
    Avatar,
    Button,
    CircularProgress,
    createStyles,
    Grid,
    List,
    ListItem,
    makeStyles,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { ArrowDownward, ChevronLeft, ChevronRight, Clear, FirstPage, LastPage, Search } from '@material-ui/icons';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import MaterialTable, { Column, Icons, MTableBodyRow } from '@synerate/material-table';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import domain from '../../config/domain';
import mrvLove from '../../mrvLove.png';

const tableIcons: Icons = {
    FirstPage: forwardRef(() => <FirstPage />),
    LastPage: forwardRef(() => <LastPage />),
    NextPage: forwardRef(() => <ChevronRight />),
    PreviousPage: forwardRef(() => <ChevronLeft />),
    ResetSearch: forwardRef(() => <Clear />),
    Search: forwardRef(() => <Search />),
    SortArrow: forwardRef(() => <ArrowDownward />),
};

const getMovies = async (guildId: string) => {
    const res = await fetch(`${domain}/api/list/guild/${guildId}`);

    return res.json();
}

const defaultMovie: any = {
    addedBy: {
        name: '',
        id: '',
    },
    addedOn: null,
    title: '',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        context: {
            width: '100%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
        },
        table: {
            '& tr[data-locked="1"]': {
                background: 'rgba(0, 0, 0, .25)',
                textDecoration: 'line-through rgba(255, 255, 255, .6) 1px',
                color: 'rgba(255, 255, 255, .4)'
            },
            '& tr[data-locked="2"]': {
                background: 'rgba(255, 0, 0, .25)',
                textDecoration: 'wavy line-through red 4px',
            }
        },
        guildIcon: {
            width: 128,
            height: 128,
            boxShadow: theme.shadows[11],
            marginBottom: 20,
        }
    }),
);

const MovieList = () => {
    const [context, setContext] = useState({
        id: null,
        x: 0,
        y: 0,
    });
    const [guild, setGuild] = useState(null);
    const [movies, setMovies] = useState([]);
    const [config, setConfig] = useState(null);
    const [newMovie, setNewMovie] = useState(defaultMovie);

    const { guildId } = useParams();
    const classes = useStyles();

    const add = false;

    const addMovie = async () => {
        const res = await fetch(
            `${domain}/api/list/guild/${guildId}`,
            {
                body: JSON.stringify(newMovie),
                method: 'POST',
            }
        );
        return res.json();
    };

    // TODO: handle menu state to allow copying of the ID :)
    const contextMenu = (event: any) => {
        const parent: HTMLTableRowElement = event.target.parentElement;
        console.log(parent.dataset)
        if (
            parent.nodeName === 'TR'
            &&
            parent.dataset.id
        ) {
            event.preventDefault();

            setContext({
                id: parent.dataset.id,
                x: event.clientX - 2,
                y: event.clientY - 4,
            });
        }
    };

    const copyId = (id: string) => {
        navigator.permissions.query({ name: 'clipboard-write' } as any)
            .then(async res => {
                if (res.state === 'granted' || res.state === 'prompt') {
                    return navigator.clipboard.writeText(id)
                        .then(unsetContextMenu)
                        .catch(err => console.error('Failed to write to clipboard', err));
                }
            });
    };

    const unsetContextMenu = () => setContext({ id: null, x: 0, y: 0 });

    const cols: Column<object>[] = [
        { field: '_id', title: 'ID', width: 1, hidden: true, },
        { field: 'title', title: 'Title', width: '100%', },
        { field: 'addedBy', title: 'Added By', width: 1, align: 'right', },
        { field: 'addedOn', title: 'Added', width: 1, align: 'right', type: 'date', defaultSort: 'desc', },
        { field: 'locked', title: 'Locked', width: 1, hidden: true, },
    ];

    useEffect(() => {
        let isLoaded = true;

        const loadData = async () => {
            if (isLoaded) {
                const data = await getMovies(guildId);
                console.log(data);

                setGuild(data.guild);
                setMovies(data.items);
                setConfig(data.config);
            }
        };

        loadData();

        return () => {
            isLoaded = false;
        };
    }, [ guildId ]);

    if ( !guild ) {
        return movies?.length >= 0
            ? <div className="container">
                <CircularProgress size={100} />
            </div>
            : <div className="container">
                This list is empty.
            </div>;
    }

    return <>
        <Helmet>
            <title>{ guild.name } | List</title>
        </Helmet>
        <div className="container" onClick={unsetContextMenu}>
            <Avatar
                className={ classes.guildIcon }
                src={ guild.avatar }
            />
            <Typography variant="h4" color="primary">
                { guild.name }
            </Typography>
            <br />
            { config !== null && <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <main className="main" style={{ backgroundColor: blueGrey[800] }} >
                            <h3>When</h3>
                            { config.when &&
                                Object.entries(config.when).map(([day, { start, end }]: any[], i) =>
                                    <div>
                                        <b>{ capitalize(day) }: </b>
                                        <i>{ start } - { end }</i>
                                    </div>
                                )
                            }
                            <br />
                        </main>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <main className="main" style={{ backgroundColor: blueGrey[800] }} >
                            <h3>Where</h3>
                            { config.where.external && <>
                                <a href={ config.where.external } target="_BLANK" rel="noreferrer noopener">{ config.where.external }</a>
                            </> }
                            <br />
                        </main>
                    </Grid>
                </Grid>
            </> }
            <main className="main" style={{ backgroundColor: blueGrey[800] }} >
                <h3>list</h3>

                <div
                    onContextMenu={contextMenu}
                    className={ classes.table }
                >
                    { context.id &&
                        <List
                            component="nav"
                            className={clsx('contextMenu', classes.context)}
                            style={{
                                top: context.y,
                                left: context.x,
                            }}
                        >
                            <ListItem button onClick={() => copyId(context.id)}>
                                Copy ID
                            </ListItem>
                        </List>
                    }
                    <MaterialTable
                        style={{
                            width: '100%',
                            boxShadow: 'none',
                            border: 'none',
                            backgroundColor: blueGrey[800],
                            marginTop: '-1rem',
                            whiteSpace: 'nowrap',
                        }}
                        data={movies.map((movie: any) => ({
                            id: movie._id,
                            title: movie.title,
                            addedOn: new Date(movie.addedOn),
                            addedBy: movie.addedBy.name,
                            locked: movie.locked,
                        }))}
                        columns={cols}
                        icons={tableIcons}
                        options={{
                            draggable: false,
                            headerStyle: {
                                backgroundColor: blueGrey[800],
                            },
                            searchFieldStyle: {
                                backgroundColor: blueGrey[800],
                            },
                            filterCellStyle: {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            },
                            padding: 'dense',
                            pageSize: 15,
                            pageSizeOptions: [],
                            searchFieldAlignment: 'right',
                            searchFieldVariant: 'outlined',
                            showTitle: false,
                            selection: false,
                        }}
                        components={{
                            Row: rowProps => <MTableBodyRow data-id={rowProps.data?.id} data-locked={rowProps.data?.locked} { ...rowProps } />
                        }}
                    />
                </div>
            </main>

            { add &&
                <main
                    className="main"
                    style={{
                        backgroundColor: blueGrey[800],
                    }}
                >
                    <h3>Add an entry</h3>
                    <LocalizationProvider dateAdapter={ DateFnsUtils }>
                        <TextField
                            value={newMovie.title}
                            onChange={ event => setNewMovie((preValue: any) => ({ ...preValue, title: String(event.target.value) })) }
                            label="Title"
                            fullWidth
                        />
                        <DateTimePicker
                            renderInput={ props => <TextField fullWidth { ...props } /> }
                            value={newMovie.addedOn}
                            onChange={ date => setNewMovie((preValue: any) => ({ ...preValue, addedOn: new Date(date).toString() })) }
                            label="Added On"
                        />
                        <TextField
                            value={newMovie.addedBy.name}
                            onChange={ event => setNewMovie((preValue: any) => ({ ...preValue, addedBy: { ...preValue.addedBy, name: String(event.target.value) } }) ) }
                            label="Added By Username"
                            fullWidth
                        />
                        <TextField
                            value={newMovie.addedBy.id}
                            onChange={ event => setNewMovie((preValue: any) => ({ ...preValue, addedBy: { ...preValue.addedBy, id: String(event.target.value) } }) ) }
                            label="Added By ID"
                            fullWidth
                        />
                        <br />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={ addMovie }
                        >Add Entry</Button>
                        <br />
                    </LocalizationProvider>
                </main>
            }
        </div>
        <div style={{ textAlign: 'center', margin: -5 }}>
            <Tooltip title="Built with ♥ by MRVDOG">
                <img
                    height={ 64 }
                    aria-label=":mrvLove:"
                    src={ mrvLove }
                    alt=":mrvLove:"
                    draggable="false"
                />
            </Tooltip>
        </div>
    </>;
}

export default MovieList;
