import { Grid } from '@material-ui/core';
import { Embed, Generator, Visualizer } from 'discord-embed-visualizer';
import React, { useState } from 'react';

const DemoDefaults: Embed = {
    author: {
        name: 'MRVDOG',
        url: 'https://mrv.dog',
        iconUrl: 'https://cdn.discordapp.com/icons/670674082202648586/a_1d0a762ba11c05cc2a8d1bfcd352e9e5.webp?size=32',
    },
    title: 'Discord Embed Visualizer',
    url: 'https://dev.mrv.dog/examples/discord-embed-visualizer',
    description: '## Supports Markdown too :)\n have a play using the Generator to the left',
    color: '#F00',
    timestamp: new Date().toString(),
    image: {
        url: '',
        width: 0,
        height: 0
    },
    thumbnail: {
        url: '',
        width: 0,
        height: 0
    },
    footer: {
        iconUrl: 'https://cdn.discordapp.com/avatars/675781331229343748/311271a9d574e1dd621a25db46126e19.png?size=32',
        text: 'via Sir. Yorke',
    },
    fields: [{
        name: 'Field 1',
        value: 'Some content',
        inline: true,
    }, {
        name: 'Field 2',
        value: 'Some content',
        inline: true,
    }, {
        name: 'Field 3',
        value: 'Some content',
        inline: true,
    }, {
        name: 'Field 4',
        value: 'Some content',
        inline: false,
    }, {
        name: 'Field 5',
        value: 'Some content',
        inline: true,
    }, {
        name: 'Field 6',
        value: 'Some content',
        inline: true,
    }],
};

const MyEmbed = () => {
    const [ data, setData ] = useState<Embed>(DemoDefaults);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Generator defaultValue={ DemoDefaults } onChange={ setData } />
                </Grid>
                <Grid item xs={6}>
                    <Visualizer
                        bot={{
                            name: 'Sir. Yorke',
                            iconUrl: 'https://cdn.discordapp.com/avatars/675781331229343748/311271a9d574e1dd621a25db46126e19.png?size=128',
                        }}
                        embed={ data }
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default MyEmbed;
