import './index.scss';

import logo from '../logo.svg';

function Homepage() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
        </header>
    );
};

export default Homepage;
